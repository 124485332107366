import React from 'react';
import Modal from './Modal';

const AryModal = ({
  open,
  handleClose,
  title,
  noCloseOnOutsideClicked,
  noCloseIcon,
  width,
  height,
  justifyContent,
  alignItems,
  rowSpacing,
  columnSpacing,
  descriptionSpacingToTitle,
  style,
  children
}) => {
  const modalStyle = {
    width: width || '46.875rem',
    height: height || 'auto',
    ...style
  };
  const modalDatatyles = {
    'margin-top': descriptionSpacingToTitle || '1rem',
    'row-gap': rowSpacing || '1.5rem',
    'column-gap': columnSpacing || '1rem',
    justifyContent,
    alignItems
  };

  return (
    <Modal
      open={open}
      onClose={noCloseOnOutsideClicked ? () => {} : handleClose}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      }}
    >
      <div className="modal-box" style={{ ...modalStyle }}>
        <div className="modal-items--wrapper">
          <div className="modal-title--holder">
            <h3 className="modal-title">{title}</h3>
          </div>
          {!noCloseIcon && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div className="icon-wrapper" onClick={handleClose}>
              <img
                src="/static/img/brands/close-x.svg"
                alt="close"
                style={{
                  height: '1.5rem',
                  width: '1.5rem',
                  fill: 'rgba(0, 0, 0, 0.54)'
                }}
              />
            </div>
          )}
        </div>
        <div className="modal-data" style={{ ...modalDatatyles }}>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default AryModal;
